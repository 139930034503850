import {Component, Inject, LOCALE_ID, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Event, Router, RoutesRecognized} from '@angular/router';
import {CommonService} from './_services/common.services';
import {AuthenticationService} from './_services/authentication.service';
import {MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration} from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus,  } from '@azure/msal-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import {Platform} from '@angular/cdk/platform';
import {DOCUMENT} from '@angular/common';
import {AuthService} from "./core/auth/auth.service";
@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent
{
    /**
     * Constructor
     */
    constructor(
        public aS: AuthService,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        @Inject(LOCALE_ID) private localeId: string,
        private platform: Platform, private authService: AuthenticationService, private router: Router,
        private route: ActivatedRoute, public cs: CommonService,
        private msalAuthService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) {
        // @ts-ignore
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                // @ts-ignore
                this.currentUrl.next(event.urlAfterRedirects);
            }
        });

        if (this.platform.BLINK) {
            this.renderer.addClass(this.document.body, 'is-blink');
        }

        this.route.queryParamMap.pipe(
            filter(queryParamMap => queryParamMap.has('code'))
        ).subscribe((queryParamMap) => {
        });
    }

    ngOnInit(): void {
        // @ts-ignore
        this.router.events.subscribe((event: any) => {
            if (!!event && event instanceof RoutesRecognized) {
                const params = event.state.root.firstChild.queryParams;
                if (params.userName && params.otp) {
                    localStorage.setItem('userName', params.userName);
                    localStorage.setItem('otp', params.otp);
                }
            }
        });
        let token: any;
        window.addEventListener('storage', (event) => {
            localStorage.removeItem('tabinactive');
            if (event.storageArea === localStorage) {
                token = localStorage.getItem('isLoggedin');
                if (token === undefined || token == null) {
                    if (!window.location.href.includes('/login')) {
                        sessionStorage.clear();
                        localStorage.clear();
                        this.msalAuthService.logout();
                    }
                }
            }
        });
        const i = 0;
        token = localStorage.getItem('isLoggedin');
        const m: any = this.msalAuthService.instance.getAllAccounts();
        localStorage.setItem('un', m[0]?.name);
        if (!this.aS._user) {
            this.aS._user = {};
        }
        this.aS._user.name = m[0]?.name;
        this.aS._user.email = '';
        console.log(m);
        if (token === undefined || token == null) {
            this.router.navigate(['/login']);
        } else {
            this.msalBroadcastService.msalSubject$ .subscribe((result: EventMessage) => { });
        }
    }
}
