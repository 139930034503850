// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    client_id: '295cf114-fabd-42f8-8ea5-1e6f343bc950',
    authority: 'https://login.microsoftonline.com/f4c5b664-ce28-4d20-825b-9d7005d6f98b',
    redirectUri: 'https://expo.mycybercns.com/login',
    postLogoutRedirectUri:'https://expo.mycybercns.com/login',
};

/*response_type: 'id_token token',
        post_logout_redirect_uri: 'https://expo.mycybercns.com/login',
        loadUserInfo: false,
        redirect_uri: 'https://expo.mycybercns.com/login/authcallback',
        silent_redirect_uri: 'https://expo.mycybercns.com/login/authcallback',
        response_mode: 'fragment',
        scope: 'https://botnotchidam.onmicrosoft.com/IDAM/user_impersonation openid profile'*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 * secret : zUh8Q~XMWKzHymaDFpD-HebOw48e1cUJV0pIWbN9
client id :
* https://botnotchidam.b2clogin.com/botnotchidam.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_SIGNIN&client_id=295cf114-fabd-42f8-8ea5-1e6f343bc950&nonce=
* defaultNonce&redirect_uri=https%3A%2F%2Fjwt.ms&scope=openid&response_type=code&prompt=login
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


