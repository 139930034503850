import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';

import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ToastrModule } from 'ngx-toastr';
const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};
import {
    MsalGuard,
    MsalInterceptor,
    MsalBroadcastService,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalGuardConfiguration,
    MsalRedirectComponent
} from '@azure/msal-angular';
import {
    IPublicClientApplication,
    PublicClientApplication,
    InteractionType,
    BrowserCacheLocation,
    LogLevel
} from '@azure/msal-browser';
import { msalConfig, protectedResources } from './auth-config';
@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        FormsModule,
        MatIconModule,
        // Layout module of your application
        LayoutModule,
        ToastrModule.forRoot({
            timeOut: 8000,
            progressBar: true,
            enableHtml: true,
            closeButton: true,
            preventDuplicates: true,
        }),
        MsalModule.forRoot(new PublicClientApplication(msalConfig),
          {
            // The routing guard configuration.
            interactionType: InteractionType.Redirect,
            authRequest: {
              scopes: protectedResources.appListApi.scopes
            }
          },
          {
            // MSAL interceptor configuration.
            // The protected resource mapping your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map([
              [protectedResources.appListApi.endpoint, protectedResources.appListApi.scopes]
            ])
          }),
        // 3rd party modules that require global configuration via forRoot
    ],
    bootstrap   : [
        AppComponent, MsalRedirectComponent
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true},
        MsalGuard,
    ],
})
export class AppModule
{
}
